<template>
	<div class="index">
		<top-search :diyJump="true" @changeMode="changeMode"></top-search>
		<div class="type_lists">
			<div class="type_item">
				<div class="tit">{{current_mode==1?'转让':'求购'}}</div>
				<div class="lists">
					<div class="item" v-for="(item,index) in goods_category.transfer" :key="index" @click="toGoodsCategory(item.category_id)">{{item.category_name}}</div>
				</div>
			</div>
			<div class="type_item">
				<div class="tit">{{current_mode==1?'服务':'外包'}}</div>
				<div class="lists">
					<div class="item" v-for="(item,index) in goods_category.service" :key="index" @click="toGoodsCategory(item.category_id)">{{item.category_name}}</div>
				</div>
			</div>
		</div>
		<div class="category_lists" ref="scrollBox">
			<div class="item" ref="scrollItem" @click="chooseCategory(item.category_id,index,item.attr_class_id)" :class="category_id == item.category_id ? 'active' : ''" v-for="(item,index) in all_category" :key="index">{{item.category_name}}<div :class="category_id == item.category_id ? 'active' : ''" class="line"></div>
			</div>
		</div>
		<!-- v-if="current_mode == 1" -->
		<div class="screen_list" >
			<div class="screen_item" :class="order=='modify_time'?'active':''" @click="refresh('modify_time')">
				最新
				<div class="arrows" :class="order=='modify_time'?sort:''">
					<div class="el-icon-caret-top"></div>
					<div class="el-icon-caret-bottom"></div>
				</div>
			</div>
			<div class="screen_item" :class="order=='price'?'active':''" @click="refresh('price')">
				价格
				<div class="arrows" :class="order=='price'?sort:''">
					<div class="el-icon-caret-top"></div>
					<div class="el-icon-caret-bottom"></div>
				</div>
			</div>
			<div class="screen_item" :class="order=='click_num'?'active':''" @click="refresh('click_num')">
				人气
				<div class="arrows" :class="order=='click_num'?sort:''">
					<div class="el-icon-caret-top"></div>
					<div class="el-icon-caret-bottom"></div>
				</div>
			</div>
			<div class="screen_item show" v-if="current_mode == 1" @click="changeShowStyle()">
				展示
				<img class="icon_img" v-if="show_style == 1" src="@/assets/images/newImg/show1.png" />
				<img class="icon_img" v-if="show_style == 2" src="@/assets/images/newImg/show2.png" />
				<img class="icon_img" v-if="show_style == 3" src="@/assets/images/newImg/show3.png" />
			</div>
		</div>
		<div v-loading="goods_loading">
			<template v-if="current_mode == 1">
				<div class="goods_list two" v-if="show_style == 1">
					<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
						<img class="goods_img" :src="item.goods_image ? $img(item.goods_image.split(',')[0],{ size: 'mid' }) : $img(defaultGoodsImage)" alt="" @error="item.goods_image = defaultGoodsImage">
						<div class="goods_info">
							<div class="top">
								<div class="goods_tit">{{item.goods_name}}</div>
								<div class="goods_tag">
									<div class="tag" v-if="item.agent_member_id != 0">官验</div>
									<div class="tag">{{item.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="accredits">
									<div class="item"v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
								</div>
							</div>
							<div class="bottom_info">
								<div class="left">
									<img class="logo" @click.stop="toMemberInfo(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
									<div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div>
									<img src="@/assets/images/newImg/message.png" alt="" @click.stop="evaluateGoods(item,'comment')">
									<img src="@/assets/images/newImg/kefu.png" alt="" @click.stop="toViewChat(item.member_info.member_id)">
									<div class="iconfont1 icon-gouwuche purchase" :class="item.is_cart?'active':''" @click.stop="purchaseGoods(item)"></div>
									<!-- <img class="purchase" src="@/assets/images/newImg/purchase.png" alt=""> -->
								</div>
								<div class="price">￥<div class="num">{{Number(item.price)}}</div></div>
							</div>
						</div>
					</div>
				</div>
				<div class="goods_list four" v-if="show_style == 2">
					<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
						<img class="goods_img" :src="item.goods_image ? $img(item.goods_image.split(',')[0],{ size: 'mid' }) : $img(defaultGoodsImage)" alt="" @error="item.goods_image = defaultGoodsImage">
						<div class="goods_tit">{{item.goods_name}}</div>
						<div class="goods_tags">
							<div class="tag" v-if="item.agent_member_id != 0">官验</div>
							<div class="tag">{{item.category_name}}</div>
							<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
						</div>
						<div class="accredits">
							<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
						</div>
						<div class="bottom">
							<div class="left">
								<img class="logo" @click.stop="toMemberInfo(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
								<div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div>
								<img src="@/assets/images/newImg/message.png" alt="" @click.stop="evaluateGoods(item,'comment')">
								<div class="iconfont1 icon-gouwuche purchase" :class="item.is_cart?'active':''" @click.stop="purchaseGoods(item)"></div>
							</div>
							<div class="price">￥<div class="number">{{Number(item.price)}}</div></div>
						</div>
					</div>
				</div>
				<div class="goods_list excel" v-if="show_style == 3 && goods_list.length">
					<div class="list_tit">
						<div>分类名称</div>
						<div>商品名称</div>
						<div v-for="(item,index) in attr_list" :key="index" v-if="item.excel_show">{{item.attr_name}}</div>
						<div>发布</div>
						<div>官验</div>
						<div>价格</div>
						<div></div>
					</div>
					<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
						<div>{{item.category_name}}</div>
						<div>{{item.goods_name}}</div>
						<div v-for="(aItem,aIndex) in item.excel_show" :key="aIndex">{{aItem.attr_value_name}}</div>
						<div>{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
						<div>{{item.agent_member_id ? '官验':'无'}}</div>
						<div>{{Number(item.price)}}</div>
						<div><img v-if="item.member_info" @click.stop="toMemberInfo(item.member_info.member_id)" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" /></div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="goods_list demand" >
					<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
						<div class="content">
							<div class="goods_tit">{{item.goods_name}}</div>
							<div class="tags">
								<div class="tag" v-if="item.agent_member_id != 0">官验</div>
								<div class="tag">{{item.category_name}}</div>
								<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
							</div>
							<div class="accredits">
								<div class="item"v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
							</div>
						</div>
						<div class="bottom_info">
							<div class="left">
								<img class="logo" @click.stop="toMemberInfo(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
								<!-- <div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div> -->
								<img src="@/assets/images/newImg/message.png" alt="" @click.stop="evaluateGoods(item,'comment')">
								<img src="@/assets/images/newImg/kefu.png" alt="" @click.stop="toViewChat(item.member_info.member_id)">
								<img src="@/assets/images/newImg/invitation.png" alt="" @click.stop="evaluateGoods(item,'tender')">
								<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
							</div>
							<div class="price">￥<div class="num">{{Number(item.price)}}</div></div>
						</div>
					</div>
				</div>
			</template>
			<!-- <div v-if="!goods_list.length" class="empty_text">暂无{{current_mode==1?'资源':'需求'}}</div> -->
		</div>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
	import topSearch from '@/components/top_search';
	import index from './_index/index.js';
	import servicerMessage from "@/components/message/servicerMessage"
	export default {
		name: 'index',
		components: {
			topSearch,
			servicerMessage
		},
		
		mixins: [index]
	};
</script>

<style lang="scss" scoped>
	@import './_index/index.scss';
</style>
